
import './App.css';
import Mainpage from './components/Mainpage';

function App() {
  return (
    <div className="App">
     <Mainpage/>
    </div>
  );
}

export default App;
